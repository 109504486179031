import * as React from "react";
import FirstScreenImage from "../images/1.png";
import SecondScreenImage from "../images/2.png";
import ThirdScreenImage from "../images/3.png";
import FourthScreenImage from "../images/4.png";
import FiveScreenImage from "../images/5.png";
import SixScreenImage from "../images/6.png";
import "../styles/base.css";
import { ActionCallSection } from "../components/sections/ActionCallSection";
import { ChatbotAI } from "../components/sections/ChatbotAI";
import { SmartSorting } from "../components/sections/SmartSorting";
import { Feature } from "../components/sections/Feature";
import { VirtualAssistant } from "../components/sections/VirtualAssistant";
import { RPA } from "../components/sections/RPA";
import { Goods } from "../components/sections/Goods";
import { GetInTouch } from "../components/sections/GetInTouch";
import { Locations } from "../components/sections/Locations";
import { Text } from "../components/utils/Text";
import { List } from "../components/utils/List";
import { ListItem } from "../components/utils/ListItem";
import { Blog } from "../components/sections/Blog";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <div className="bg-white">
      <ActionCallSection
        mainTitle={"Astilabor"}
        mainSubTitle={"Develop your future"}
        description={"Personal AI development for any business"}
      />
      <div className="relative bg-white  lg:pb-0 overflow-hidden">
        <div className="pb-16  content-center items-center">
          <ChatbotAI image={SecondScreenImage} Header="Chatbot + AI">
            <Text className="mt-4 text-lg text-gray-500">
              Chatbot + AI = Virtual <br />A chatbot is expected to be on every
              webpage. Chatbot with AI is now built into ERP. Chatbot in
              Customer Service usually has humans as escalating point.
            </Text>
            <Text className=" mt-4 mb-2 text-lg text-gray-700">
              Our Virtual Assistant can perform quick actions to help the user
              with frequent tasks:
            </Text>
            <List>
              <ListItem>Help to enter data</ListItem>
              <ListItem>Give help or respond on question</ListItem>
            </List>
          </ChatbotAI>
        </div>

        <div className=" pb-16  content-center items-center">
          <SmartSorting
            image={SixScreenImage}
            Header={"The benefits of our applications\n"}
          >
            <Text className="mt-4 text-lg text-gray-700">
              The most important functions that our products can assist with:
            </Text>
            <List>
              <ListItem>Automate existing functions</ListItem>
              <ListItem>Make decisions</ListItem>
              <ListItem>Create new products</ListItem>
              <ListItem>Optimize internal processes</ListItem>
              <ListItem>Automate tasks, make workers available</ListItem>
            </List>
          </SmartSorting>
        </div>
        <div className=" pb-16  content-center items-center">
          <Feature
            image={ThirdScreenImage}
            Header="Image text recognition + decision based on ML = automated approvals"
          >
            <Text className="mt-4 text-lg text-gray-500">
              The coolest thing we did recently was to use AI/ML to make a
              working concept for automate a manual process for large companies
              related to bookkeeping and approvals. A very talented specialist
              helped us predict the correct answer. It took months of work but
              can replace much more. Many manual steps will be automated in the
              future.
            </Text>
          </Feature>
        </div>
      </div>

      {/*//////////////////////////////////////    */}
      <div className="relative bg-white  overflow-hidden">
        <div className=" pb-16  content-center items-center">
          <VirtualAssistant
            image={FourthScreenImage}
            Header="Virtual Assistant"
          >
            <Text className="mt-4 text-lg text-gray-700">
              Chatbot is a mature technology. The main benefit is that it saves
              time, make fewer mistakes, available always.
            </Text>
            <Text className="mt-4 text-lg text-gray-700">
              Virtual Assistant can do more than Chatbot
            </Text>
            <List className="text-lg leading-8 text-violet-900 list-disc list-inside">
              <ListItem>Understand Natural language without keywords</ListItem>
              <ListItem>Create users, solve more difficult questions</ListItem>
            </List>
            <Text className="mt-4 text-lg text-gray-700">
              Our Virtual Assistant is built in Angular 7, python, MongoDB,
              Rasa, Docker.
            </Text>
          </VirtualAssistant>
        </div>
        <div className=" pb-16  content-center items-center">
          <RPA image={FiveScreenImage} Header="RPA: automate: UiPath">
            <Text className="mt-4 text-lg text-gray-500">
              We can build AI models and Robotic Process Automation (RPA) in
              UiPath that are able to automate manual processes in your company.
            </Text>
          </RPA>
        </div>
        <div className="pb-16  content-center items-center">
          <Goods image={FirstScreenImage} Header="E-commerce">
            <Text className=" mt-4 mb-2 text-lg text-gray-700">
              Major retail companies use AI too create
            </Text>
            <List className="text-lg leading-8 text-violet-900 list-disc list-inside">
              <ListItem>Marketing</ListItem>
              <ListItem>Next-Best Offer</ListItem>
              <ListItem>Product recommendations</ListItem>
            </List>
            <Text className="mt-4 text-lg text-gray-500">
              At the bottom of the picture you can see other goods that the
              customer may be interested in. Machine learning chooses from
              40,000 products and increases sales. A function we as customers
              are used to seeing, but which is constantly getting better.
              Creepily accurate, advertising appears that suits the customer.
            </Text>
          </Goods>
        </div>
      </div>
      <Blog
        title={"The blog"}
        subtitle={
          ""
        }
      />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="divide-y-2 divide-gray-200">
            <GetInTouch />
            <Locations />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
