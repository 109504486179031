import React from "react";

export const GetInTouch = () => {
    return (
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Get in touch
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <dl className="mt-2 text-base text-gray-500">
                    <div>
                    <dt className="sr-only">Email</dt>
                    <a href="mailto:AstilaborOU@gmail.com hover:text-gray-600">
                        AstilaborOU@gmail.com
                    </a>
                    </div>
                    <div className="mt-1">
                    <dt className="sr-only">Phone number</dt>
                    <a href="tel:+46812159050 hover:text-gray-600">
                        +46812159050
                    </a>
                    </div>
                    <div className="mt-1">
                        <a href="https://www.linkedin.com/company/astilaborou/" className="text-gray-500 hover:text-gray-600">
                            Linkedin
                        </a>
                    </div>
                </dl>
            </div>
        </div>
    )
}
