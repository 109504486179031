import React, { useEffect, useState } from "react";
import { getPosts, toggleHiddenPost } from "../utils/firebase";
import { navigate } from "gatsby";

export const Blog = ({ title, subtitle }) => {
  const [posts, setPosts] = useState([]);
  const [maxPosts, setMaxPosts] = useState(6);

  useEffect(() => {
    getPosts().then((r) => {
      setPosts(
        r.filter((post) => {
          return post.hidden === false;
        })
      );
    });
  }, [maxPosts]);

  const onPostClick = (slug) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <div className="relative bg-gray-50 pb-20 px-4 sm:px-6">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {title}
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            {subtitle}
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.slice(0, maxPosts).map((post) => (
            <div
              onClick={() => {
                onPostClick(post.slug);
              }}
              key={post.id}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer hover:shadow-xl"
            >
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.text.slice(0, 140)}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.author}>
                      <span className="sr-only">{post.author}</span>
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <div className="hover:underline">{post.author}</div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {maxPosts < posts.length ? (
          <button
            type="button"
            onClick={() => {
              setMaxPosts(maxPosts + 6);
            }}
            className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md flex items-center justify-center bg-violet-600 text-white hover:bg-violet-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 "
          >
            Show more
          </button>
        ) : null}
      </div>
    </div>
  );
};
