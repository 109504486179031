import React from "react";
import Logotype from "../../images/astilabor_logo.svg";

export const ActionCallSection = ({ mainTitle, mainSubTitle, description }) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto pt-16 px-4 sm:pt-24 sm:px-6 lg:pb-0 pb-6 lg:px-8">
      <div className="text-center">
        <img
          src={Logotype}
          alt="Astilabor logotype"
          className="w-36 mx-auto mb-3"
        />

        <h2 className="text-base font-semibold text-violet-600 tracking-wide uppercase">
          {mainSubTitle}
        </h2>
        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
          {mainTitle}
        </p>
        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
          {description}
        </p>
      </div>
    </div>
  </div>
);
