import React from 'react';

export const Locations = () => {
    return (
        <div className="mt-8 pt-8 lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Locations
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                <div className="mt-2 text-base text-gray-500">
                    <p>ASTILABOR OÜ (14860114)</p>
                    <p className="mt-1">Harjumaa, Tallinn linn, Padriku tee 12/3-4, 11912</p>
                </div>
                </div>
            </div>
        </div>
    )
}